:root {
  .light {
    --transparent: rgba(0, 0, 0, 0);
    --white: #ffffff;
    --black: #000000;
    --tomato: #f44336;
    --purple: #843dff;
    --green: #4caf50;
    --orange: #ff9800;
    --control: #e2e8f0;
    --background: #FAFAFA;
    --foreground: #222222;
    --foreground-dim: #a0aec0;
    --foreground-light: #cbd5e0;
  }
  .dark {
    --transparent: rgba(0, 0, 0, 0);
    --black: #ffffff;
    --white: #000000;
    --tomato: #f77970;
    --purple: #843dff;
    --green: #8bc34a;
    --orange: #ffb13d;
    --control: #192226;
    --background: #233035;
    --foreground: #d7e1e4;
    --foreground-dim: #638997;
    --foreground-light: #506e7a;
  }
}

@tailwind base;

body {
  font-size: 14px;
  line-height: 1.6em;
  &::-webkit-scrollbar { width: 0 !important }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

input:focus {
  outline: none;
}

@tailwind components;
@tailwind utilities;

.row {
  a {
    @apply text-tomato;
  }
}

code {
  &::before {
    content: "「 ";
    opacity: 0.5;
  }
  &::after {
    content: " 」";
    opacity: 0.5;
  }
}

.task-content {
  p {
    display: inline-block;
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: shadow-pulse 1s infinite;
}